<template>
  <div style="position: relative;"  v-if="field.Visable">
    <div class="myInput report-v2">
      <van-cell-group style="background-color: #F2F3F5;">
        <van-field :required="field.Nullable == true" :disabled="isEditSaved || !field.Editable"  :label-width="config.labelWidth"
                   :placeholder="config.description"  :rows="config.rows" :type="config.type" readonly
                   :style="'height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'" >
          <template slot="label">
            <div v-if="config.labelWidth !== '0'" :style="'width: ' + config.labelWidth + 'px; color: ' + pageSetup.wordColor +'; font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; height: ' +
            config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'">{{ config.name }}</div>
          </template>
          <template slot="input">
            <div
                    v-if="dataSet[tablecode][rowindex][config.FieldCode]==null || dataSet[tablecode][rowindex][config.FieldCode]==''">
              <van-button   @click="editFieldBefor" :disabled="isEditSaved || !field.Editable" size="small">选择文件</van-button>
              <input class="inputIcon" type="file" accept="*" :id="field.FieldID + '_' + rowindex" @change="onClickUploadAffix($event, 0)"
                     :style="'font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; background-color: #fff; height: ' +
          config.cellHeight + 'px; line-height: ' +  config.cellHeight
              + 'px;  '"  style="display: none;"/>
            </div>
            <div
                    v-if="dataSet[tablecode][rowindex][config.FieldCode]!=null && dataSet[tablecode][rowindex][config.FieldCode]!=''"
                    class="img-box-main">
              <div class="img-line-main"
                   :style="'font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; background-color: #fff; height: ' +
          config.cellHeight + 'px; line-height: ' +  config.cellHeight
              + 'px;  '" >
                <div style="width: 80%; float: left">
                  <a class="detail-affix"
                     @click="previewAffix(resourceHome + popAffixInfo(dataSet[tablecode][rowindex][config.FieldCode+'_id']).Url)">
                    {{dataSet[tablecode][rowindex][config.FieldCode]}}
                  </a>
                </div>
                <van-icon name="delete-o" class="delete-affix-icon"
                          @click="removeAffixV2(tablecode,config.FieldCode,rowindex)" v-if="!isEditSaved && field.Editable"/>
              </div>
            </div>
          </template>

        </van-field>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { Uploader, Loading, Field, Icon, Button, CellGroup } from 'vant'
export default {
    name: 'nxMobileFile',
    components: {
        [Uploader.name]: Uploader,
        [Field.name]: Field,
        [Loading.name]: Loading,
        [Button.name]: Button,
        [CellGroup.name]: CellGroup,
        [Icon.name]: Icon

    },
    props: {       pageSetup: Object,
        dataSet: Object,
        value: String,
        config: Object,
        children: Array,
        mastertable: Object,
        tables: Array,
        cssdiy: Object,
        label: String,
        isEditSaved: Boolean,
        onClickUploadImage: Function,
        popImageInfo: Function,
        onClickShowImage: Function,
        removeImage: Function,
        removeAffix: Function,
        resourceHome: String,
        rowindex: Number,
        tablecode: String,
        editFieldBefore: Function,
        editFieldBeforeV2: Function,
        popAffixInfo: Function,
        previewAffix: Function,
        onClickUploadAffix: Function
    },
    data () {
        return {
            weightList: {                 0: 'inherit',                 33: 'normal',                 66: 'bold',                 99: 'bolder'             },             sizeList: {                 0: '10',                 33: '14',                 66: '16',                 99: '20'             },             field: null
        }
    },
    mounted () {
        const tableList = this.tables
        tableList.push(this.mastertable)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            if (table.TableCode === this.tablecode) {
                for (let j = 0; j < table.Fields.length; j++) {
                    if (table.Fields[j].FieldCode === this.config.FieldCode) {
                        this.field = table.Fields[j]
                    }
                }
            }
            if (this.field !== null) {
                break
            }
        }
    },
    methods: {
        async editFieldBefor () {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            const result = await this.editFieldBeforeV2()
            if (result) {
                const inputFile = document.getElementById(this.field.FieldID + '_' + this.rowindex)
                inputFile.click()
            }

        },
        async removeAffixV2 (tablecode,fieldCode,index) {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            const result = await this.editFieldBeforeV2()
            if (result) {
                this.removeAffix(tablecode,fieldCode,index)
            }
        }
    }
}
</script>

<style scoped>
  .delete-affix-icon {
  }

</style>
