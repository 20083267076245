import { render, staticRenderFns } from "./nxMobilePictureList.vue?vue&type=template&id=48a59910&scoped=true&"
import script from "./nxMobilePictureList.vue?vue&type=script&lang=js&"
export * from "./nxMobilePictureList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a59910",
  null
  
)

export default component.exports